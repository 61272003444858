import { AppState, NavEvent, QuickLinks } from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { StyledAd } from '../TheNightlyStickySiteHeader/TheNightlyStickySiteHeader.styled'
import { addListener, removeListener } from '../../__helpers/global-dom-events'
import { DimensionProps, withDimensions } from '../../__helpers/with-dimensions'
import { TNNavHeaderWithStickyBanner } from '../../user-registration'
import {
    HeaderDisplayState,
    HeaderScrollType,
    getEndAvailableFutureState,
} from './helper/site-header-state-helper'
import debug from 'debug'
import { ProviderChildProps } from '../../navigation'

const theNightlyStickySiteHeaderDebug = debug('site-header')
const stateDebug = theNightlyStickySiteHeaderDebug.extend('state')
const scrollPositionDebug = theNightlyStickySiteHeaderDebug.extend('position')

export interface TheNightlyStickySiteHeaderProps {
    headerAd?: (rendered?: () => void) => JSX.Element | undefined
    onEvent: (event: NavEvent) => void

    navigation: JSX.Element
    disableSticky?: boolean
    quickLinks: QuickLinks
    flyOutProps?: ProviderChildProps
}

type InternalProps = TheNightlyStickySiteHeaderProps & DimensionProps

export const InternalTheNightlyStickySiteHeader: React.FC<InternalProps> = (
    props,
) => {
    const renditionType = useSelector(
        (state: AppState) => state.render.renditionType,
    )

    const [currentVerticalScrollPos, setCurrentVerticalScrollPos] = useState(0)
    const [prevScrollPos, setPrevScrollPos] = useState(0)

    const [isChanging, setChanging] = useState(false)
    const [displayState, changeDisplayState] =
        useState<HeaderDisplayState>('full')
    const [scrollType, changeScrollType] =
        useState<HeaderScrollType>('scroll-down')

    const [isNavigationScroll, setIsNavigationScroll] = useState(false)

    // This will be 0 if the ad wrapper isn't being displayed.
    const adHeight = props.height

    // The ContentNav utilises scrolling and this flag prevents a change in the Header Nav
    // if the user clicks on a navigation link.
    const handleScrollNavigation = () => {
        setIsNavigationScroll(true)
    }

    /**
     * Checks viewport scroll level.
     * If user has scrolled over scrollTarget in px it sets the component to hidden and it scrolls off the page.
     * Upon scrolling up, it checks if the component is hidden and brings it back into view
     */

    const handleScroll = () => {
        setCurrentVerticalScrollPos(window.scrollY)
    }

    useEffect(() => {
        scrollPositionDebug(
            `Scroll position state update from ${prevScrollPos} to ${currentVerticalScrollPos}`,
        )

        if (isNavigationScroll) {
            setIsNavigationScroll(false)
            setPrevScrollPos(currentVerticalScrollPos)
            return
        }

        const currentScrollState =
            currentVerticalScrollPos < prevScrollPos
                ? 'scroll-up'
                : 'scroll-down'

        // The stored scroll type has changed from the current scroll type,
        // so we want to make sure it's updated for styling
        if (scrollType !== currentScrollState) {
            stateDebug(
                `Changing scroll direction from '${scrollType}' to '${currentScrollState}'.`,
            )
            changeScrollType(currentScrollState)
        }

        const nextState = getEndAvailableFutureState(
            displayState,
            currentScrollState,
            currentVerticalScrollPos,
            prevScrollPos,
            adHeight,
        )

        // no future state was found, so we want to ignore...
        if (nextState === undefined) {
            setPrevScrollPos(currentVerticalScrollPos)
            return
        }

        const { futureState } = nextState

        // The user is now scrolling the screen, so we need to set it to isChanging
        // to allow it to start manipulating the animations
        if (!isChanging) {
            setChanging(true)
        }

        changeDisplayState(futureState)
        setPrevScrollPos(currentVerticalScrollPos)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentVerticalScrollPos])

    useEffect(() => {
        addListener('scroll', handleScroll)
        return () => {
            removeListener('scroll', handleScroll)
        }
    }, [])

    const disableHeaderAd = renditionType === 'app'

    return (
        <>
            {!disableHeaderAd && renderHeaderAd()}
            <TNNavHeaderWithStickyBanner
                adHeight={adHeight}
                isChanging={isChanging}
                scrollType={scrollType}
                displayState={displayState}
                navigation={props.navigation}
                onEvent={props.onEvent}
                quickLinks={props.quickLinks}
                flyOutProps={props.flyOutProps}
                handleScrollNavigation={handleScrollNavigation}
            />
        </>
    )

    function renderHeaderAd() {
        return (
            props.headerAd && (
                <StyledAd
                    className="headerAdvertisement"
                    ref={props.innerRef}
                    height={adHeight}
                    isChanging={isChanging}
                    displayState={displayState}
                    scrollType={scrollType}
                >
                    {props.headerAd(() => props.triggerMeasure())}
                </StyledAd>
            )
        )
    }
}

export const TheNightlyStickySiteHeader =
    withDimensions<TheNightlyStickySiteHeaderProps>(
        InternalTheNightlyStickySiteHeader,
    )

TheNightlyStickySiteHeader.displayName = 'TheNightlyStickySiteHeader'
