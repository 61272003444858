import React from 'react'
import { format } from 'date-fns'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'
import {
    StyledNightlyLatestEditionContainer,
    StyledNightlyLatestEditionLink,
    StyledCardBody,
    StyledTextBox,
    StyledHeading,
    StyledTitle,
    StyledButtonWrapper,
} from './NightlyLatestEdition.styled'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'
import { breakpoint, breakpointMax } from '../../../__styling'
import NightlySpecificEdition from '../SpecificEdition/NightlySpecificEdition'
import { AppState, scaleImage } from '@news-mono/web-common'
import { useSelector } from 'react-redux'

export interface NightlyLatestEditionProps {
    edition: EditionV4DTO
    title: string
    type: 'homepage' | 'editions'
    onEvent: (event: any) => void
    lazyloadImages?: boolean
}
const HomepageLatestEdition: React.FC<
    Omit<NightlyLatestEditionProps, 'type' | 'onEvent'>
> = ({ edition, title, lazyloadImages }) => {
    const publicationDate = new Date(edition.publicationDate)
    const mainImage = edition.mainImages && {
        img: scaleImage(edition.mainImages[0].src, 600, false),
        alt: edition.mainImages[0].altText ?? edition.mainImages[0].captionText,
    }

    const inApp = useSelector(
        (state: AppState) => state.render.renditionType === 'app',
    )

    const buttons = (
        <>
            <NightlyButton
                variant={'default'}
                text={'Read the Latest Edition'}
                action={{
                    type: 'link',
                    to: `${edition.editionWebUrl}`,
                }}
                color={'primary'}
                fill={'filled'}
                layout={{ shouldGrow: true, alignSelf: 'stretch' }}
            />
            {!inApp && (
                <NightlyButton
                    variant={'default'}
                    text={`All Editions`}
                    action={{
                        type: 'link',
                        to: `/editions`,
                    }}
                    color={'primary'}
                    fill={'outline'}
                    layout={{ shouldGrow: true, alignSelf: 'stretch' }}
                />
            )}
        </>
    )

    return (
        <StyledNightlyLatestEditionContainer>
            <StyledHeading isTop>{title}</StyledHeading>
            <StyledCardBody>
                <StyledNightlyLatestEditionLink to={edition.editionWebUrl}>
                    {mainImage && (
                        <picture>
                            <source
                                srcSet={mainImage.img}
                                media={breakpointMax('sm', false)}
                            />
                            <source
                                srcSet={mainImage.img}
                                media={breakpoint('sm', false)}
                            />
                            <img
                                src={mainImage.img}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null
                                }}
                                alt={mainImage.alt}
                                loading={lazyloadImages ? 'lazy' : undefined}
                            />
                        </picture>
                    )}
                </StyledNightlyLatestEditionLink>
                <StyledTextBox>
                    <StyledHeading>{title}</StyledHeading>
                    <StyledTitle>
                        <span>Edition&nbsp;</span>
                        <span>
                            Edition {format(publicationDate, 'd')}
                            &nbsp;
                        </span>
                        <span>{format(publicationDate, 'MMMM yyyy')}</span>
                        <span>{format(publicationDate, 'd MMMM yyyy')}</span>
                    </StyledTitle>
                    <p>{edition.teaser}</p>
                    <StyledButtonWrapper>{buttons}</StyledButtonWrapper>
                </StyledTextBox>
            </StyledCardBody>
            <StyledButtonWrapper isBottom>{buttons}</StyledButtonWrapper>
        </StyledNightlyLatestEditionContainer>
    )
}

const EditionsLatestEdition: React.FC<
    Omit<NightlyLatestEditionProps, 'type' | 'title'>
> = ({ edition, lazyloadImages, onEvent }) => {
    return (
        <NightlySpecificEdition
            edition={edition}
            shouldLinkToEdition
            lazyloadImages={lazyloadImages}
            onEvent={onEvent}
        />
    )
}

export const NightlyLatestEdition: React.FC<NightlyLatestEditionProps> = (
    props,
) => {
    switch (props.type) {
        case 'editions':
            return <EditionsLatestEdition {...props} />
        case 'homepage':
        default:
            return <HomepageLatestEdition {...props} />
    }
}
