import { createRegisterableComponent, RouteStatus } from '@news-mono/web-common'
import React from 'react'
import { TheNightlySomethingWentWrong } from './TheNightlySomethingWentWrong'

export const TheNightlySomethingWentWrongRegistration =
    createRegisterableComponent('thenightly-500-page', () => (
        <RouteStatus statusCode={500}>
            <TheNightlySomethingWentWrong />
        </RouteStatus>
    ))
