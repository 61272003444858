import { TheNightlySection } from '@news-mono/common'
import { calcRem, IconChevronUpTN } from '@news-mono/component-library'
import {
    AppState,
    BaseClientConfig,
    clipStringToLength,
    GetRouteAdTargeting,
    PageType,
} from '@news-mono/web-common'
import { ProfileV4DTO } from '@west-australian-newspapers/publication-types'
import H from 'history'
import React from 'react'
import { AnyAction, Store } from 'redux'
import { layout } from '../../App.routing'
import queryString from 'query-string'
import { parsePageParam } from '../helpers'

export interface Props {
    profile: ProfileV4DTO
    config: BaseClientConfig
    getAdTargeting: GetRouteAdTargeting
    location: H.Location<any>
    section: TheNightlySection
    store: Store<AppState, AnyAction>
}

const MAIN_CONTENT_ID = 'main-content'

export const getProfilePageRouteInfo = ({
    profile,
    section,
    config,
    getAdTargeting,
    location,
}: Props): PageType<TheNightlySection> => {
    const adTargeting = getAdTargeting('profile', section, profile.slug)
    const profileUrl = `${config.publicUrl}${location.pathname}`
    const query = queryString.parse(location.search)
    const page = parsePageParam(query.page)
    const title =
        page > 1
            ? `${profile.name} | Page ${page} | ${profile.position}`
            : `${profile.name} | ${profile.position}`

    // Profiles need point back to the author's canonical site
    const canonicalUrl = profile.canonicalSite
        ? `${profile.canonicalSite}${location.pathname}`
        : profileUrl

    return {
        kind: 'page',
        heading: 'Profiles',
        hideHeading: true,
        pageType: 'profile',
        pageMeta: {
            title,
            description: canonicalUrl
                ? clipStringToLength(canonicalUrl, 160)
                : 'Journalist profile from The Nightly',
            link: [
                {
                    rel: 'canonical',
                    href: canonicalUrl,
                },
            ],
            ...(profile.mainPhoto && {
                meta: [
                    {
                        property: 'og:image',
                        content: profile.mainPhoto,
                    },
                    {
                        name: 'twitter:image',
                        content: profile.mainPhoto,
                    },
                    {
                        property: 'og:image:height',
                        content: '861',
                    },
                    {
                        property: 'og:image:width',
                        content: '1200',
                    },
                ],
            }),
        },
        socialMeta: {
            title: `${profile.name} | ${profile.position}`,
            description: profile.biography
                ? clipStringToLength(profile.biography, 160)
                : 'Journalist profile from The Nightly',
        },
        adTargeting,
        section,
        compositions: [
            layout.composition({
                type: 'box',
                props: {
                    flex: {
                        default: {
                            flexDirection: 'column',
                            alignItems: 'center',
                        },
                    },
                    horizontalGuttersOverride: 0,
                    breakpoints: {
                        sm: {
                            horizontalGuttersOverride: 20,
                        },
                        md: {
                            horizontalGuttersOverride: 0,
                        },
                    },
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'profile',
                            props: { profile, profileUrl },
                        }),
                        layout.nestedComposition({
                            type: 'collection-data-context',
                            props: {
                                contextId: MAIN_CONTENT_ID,
                                dataDefinitionArgs: {
                                    type: 'listing',
                                    topics: [],
                                    authors: [profile.slug],
                                    paging: {
                                        page: page,
                                        pageSize: 15,
                                        pageOffset: 0,
                                    },
                                },
                            },
                            contentAreas: {
                                children: [
                                    layout.component({
                                        type: 'journo-profile-card-list',
                                        props: {
                                            dataDefinitionArgs: {
                                                type: 'context',
                                                contextId: MAIN_CONTENT_ID,
                                                section:
                                                    section === 'default'
                                                        ? 'profile'
                                                        : section,
                                            },
                                            expectedItemCount: 15,
                                            adUnits: 3,
                                            adTargeting: adTargeting,
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            flex: {
                                                default: {
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    gap: calcRem(40),
                                                    hasBeforeAfterMargins: true,
                                                },
                                                sm: {
                                                    gap: calcRem(32),
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'pagination',
                                                    props: {
                                                        contextId:
                                                            MAIN_CONTENT_ID,
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'nightly-button',
                                                    props: {
                                                        variant: 'default',
                                                        text: 'BACK TO TOP',
                                                        color: 'primary',
                                                        fill: 'text',
                                                        layout: {
                                                            alignSelf: 'center',
                                                        },
                                                        action: {
                                                            type: 'button',
                                                            onClick: () =>
                                                                window.scrollTo(
                                                                    { top: 0 },
                                                                ),
                                                        },
                                                        icon: {
                                                            iconPosition:
                                                                'right',
                                                            IconElement: (
                                                                <IconChevronUpTN />
                                                            ),
                                                        },
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.component({
                            type: 'ad-unit',
                            props: {
                                adType: 'inline',
                                noticePosition: 'above-center',
                                slot: {
                                    id: 'below-page-content',
                                    size: 'billboardAbove1280leaderboardAbove768MrecBelow',
                                },
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
