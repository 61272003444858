import styled from '@emotion/styled'
import { breakpoint, calcRem, metrics, Theme } from '../../__styling'
import { textMixin } from '../../mixins/TextMixin/TextMixin'

const getHeadingStyle = (theme: Theme) => [textMixin(theme, 'section-header')]
const getSubHeadingStyle = (theme: Theme) => [textMixin(theme, 'body-alt')]

export const StyledSavedArticlesContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',

    margin: calcRem(metrics.thenightly.margins.xxl, 0),

    [breakpoint('lg')]: {
        margin: calcRem(metrics.thenightly.margins.xxxxl, 0),
    },
})

export const StyledTitleContainer = styled('div')(({ theme }) => [
    {
        display: 'flex',
        flexDirection: 'column',
        margin: calcRem(
            0,
            metrics.thenightly.margins.m2l,
            metrics.thenightly.margins.lg,
            metrics.thenightly.margins.m2l,
        ),
        gap: metrics.thenightly.margins.sm,

        [breakpoint('lg')]: {
            margin: calcRem(0, 0, metrics.thenightly.margins.lg, 0),
        },
    },
])

export const StyledPageTitle = styled('p')(({ theme }) => [
    getHeadingStyle(theme),
    {
        margin: 0,
    },
])
StyledPageTitle.displayName = 'StyledPageTitle'

export const StyledPageSubTitle = styled('p')(({ theme }) => [
    getSubHeadingStyle(theme),
    {
        margin: 0,
        display: 'none',

        [breakpoint('lg')]: {
            display: 'flex',
        },
    },
])
StyledPageSubTitle.displayName = 'StyledPageSubTitle'

export const StyledSavedArticleList = styled('div')(({ theme }) => ({
    margin: `auto ${calcRem(theme.margins.outerMargin)}`,
    [breakpoint('lg')]: {
        margin: 0,
    },
}))
StyledSavedArticleList.displayName = 'StyledSavedArticleList'
