import React, { useEffect } from 'react'
import { format } from 'date-fns'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'
import {
    StyledNightlyEditionButtonContainer,
    StyledNightlyEditionContainer,
    StyledNightlyEditionContent,
    StyledNightlyEditionPicture,
    StyledNightlyEditionTextContainer,
    StyledNightlyEditionHeading,
    StyledNightlyEditionSubHeading,
    StyledNightlyEditionTeaser,
    Divider,
} from './NightlySpecificEdition.styled'
import { breakpoint, breakpointMax } from '../../../__styling'
import { EditionV4DTO } from '@west-australian-newspapers/publication-types'
import {
    Product,
    WebLink,
    createEditionSelectItemEvent,
    createEditionViewItemListEvent,
    scaleImage,
} from '@news-mono/web-common'

export interface NightlySpecificEditionProps {
    edition: EditionV4DTO
    showAllEditionsButton?: boolean
    shouldLinkToEdition?: boolean
    lazyloadImages?: boolean
    onEvent: (event: any) => void
}

export const NightlySpecificEdition: React.FC<NightlySpecificEditionProps> = ({
    edition,
    showAllEditionsButton,
    shouldLinkToEdition,
    lazyloadImages,
    onEvent,
}) => {
    const mainImage = edition.mainImages && {
        img: scaleImage(edition.mainImages[0].src, 600, false),
        alt: edition.mainImages[0].altText ?? edition.mainImages[0].captionText,
    }

    const editionMainImg = (
        <>
            <source
                srcSet={mainImage?.img}
                media={breakpointMax('sm', false)}
            />
            <source srcSet={mainImage?.img} media={breakpoint('sm', false)} />
            <img
                loading={lazyloadImages ? 'lazy' : undefined}
                src={mainImage?.img}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = require('../../ePaper/assets/paper.jpg')
                }}
                alt={mainImage?.alt}
            />
        </>
    )

    useEffect(() => {
        showAllEditionsButton && // Only trigger the onEvent if we are on the 'Specific Edition' page
            onEvent(
                createEditionViewItemListEvent({
                    editions: [edition],
                    collectionName: 'TheNightlySpecificEditionPage',
                    itemCategory: 'thenightly_specific_edition_page',
                }),
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <StyledNightlyEditionContainer>
                <StyledNightlyEditionContent>
                    <StyledNightlyEditionPicture>
                        {mainImage && shouldLinkToEdition ? (
                            <WebLink to={edition.editionWebUrl}>
                                {editionMainImg}
                            </WebLink>
                        ) : (
                            <>{editionMainImg}</>
                        )}
                    </StyledNightlyEditionPicture>
                    <StyledNightlyEditionHeading>
                        Edition
                        <br />
                        <time dateTime={edition.publicationDate}>
                            {format(
                                new Date(edition.publicationDate),
                                'd MMM yyyy',
                            )}
                        </time>
                    </StyledNightlyEditionHeading>
                    <StyledNightlyEditionTextContainer>
                        <StyledNightlyEditionSubHeading>
                            In this Edition
                        </StyledNightlyEditionSubHeading>
                        <StyledNightlyEditionTeaser>
                            {edition.teaser}
                        </StyledNightlyEditionTeaser>
                    </StyledNightlyEditionTextContainer>
                    <StyledNightlyEditionButtonContainer>
                        <NightlyButton
                            variant={'default'}
                            text={'Read Edition'}
                            action={{
                                type: 'link',
                                to: `${edition.editionWebUrl}`,
                                onClick: () =>
                                    onEvent &&
                                    onEvent(
                                        createEditionSelectItemEvent({
                                            edition,
                                            collectionName:
                                                'TheNightlyReadEditionButton',
                                            product: Product.TheNightly,
                                            itemDetails: {
                                                category: showAllEditionsButton
                                                    ? 'thenightly_specific_edition_page'
                                                    : 'thenightly_editions_page',
                                                linkText: 'Read Edition',
                                                linkUrl: edition.editionWebUrl,
                                            },
                                        }),
                                    ),
                            }}
                            color={'primary'}
                            fill={'filled'}
                            layout={{
                                shouldGrow: true,
                                alignSelf: 'stretch',
                            }}
                        />
                        {showAllEditionsButton && (
                            <NightlyButton
                                variant={'default'}
                                text={`All Editions`}
                                action={{
                                    type: 'link',
                                    to: `/editions`,
                                }}
                                color={'primary'}
                                fill={'outline'}
                                layout={{
                                    shouldGrow: true,
                                    alignSelf: 'stretch',
                                }}
                            />
                        )}
                    </StyledNightlyEditionButtonContainer>
                    <StyledNightlyEditionTextContainer></StyledNightlyEditionTextContainer>
                </StyledNightlyEditionContent>
            </StyledNightlyEditionContainer>

            <Divider />
        </>
    )
}

NightlySpecificEdition.displayName = 'NightlyEditionFocus'

export default NightlySpecificEdition
