import { layout } from '../../App.routing'

export const breakingNewsSegment = () => {
    return layout.composition({
        type: 'box',
        props: {
            hideIfEmpty: true,
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'tn-breaking-news',
                    props: {
                        dataDefinitionArgs: {
                            type: 'curation',
                            name: 'breaking-news',
                            offset: 0,
                            pageSize: 6,
                        },
                    },
                }),
            ],
        },
    })
}
