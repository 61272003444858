import styled from '@emotion/styled'
import { calcRem, breakpoint } from '../../__styling'

interface StyledBreakingNewsContainer {
    isSingleArticleCuration: boolean
}

export const StyledBreakingNewsContainer =
    styled.div<StyledBreakingNewsContainer>(
        ({ theme, isSingleArticleCuration }) => {
            const margins = theme.margins

            const maxWidth = isSingleArticleCuration
                ? calcRem(theme.componentMetrics.imageWidth.lg)
                : calcRem(theme.siteMetrics.mainContentWidth)

            return {
                width: '100%',
                maxWidth,
                margin: calcRem(0, 0, margins.xxl),
                borderBottom: `0.5px solid ${theme.colors.borders.secondary}`,
                paddingBottom: calcRem(margins.outerMargin), // Juliet has 16px bottom padding, this makes it up to the 40px gap

                [breakpoint('sm')]: {
                    margin: '0 auto',
                    paddingBottom: calcRem(48), // Combine with Juliet padding to reach 64px gap
                },

                [breakpoint('lg')]: {
                    borderBottom: 0,
                    paddingBottom: calcRem(80), // double the gap between the components to emphasise that they are separate
                },
            }
        },
    )

export const StyledArticleListContainer = styled.div(({ theme }) => {
    const margins = theme.margins

    return {
        margin: calcRem(0, margins.outerMargin),

        [breakpoint('sm')]: {
            margin: 0,
        },
    }
})
