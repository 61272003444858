import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    AppState,
    AuthenticationState,
    authHasSignedUpNewsletterState,
    getAuth0UpdateAppMetaActionPath,
    useFeature,
} from '@news-mono/web-common'
import { useRecaptcha } from '../../../recaptcha'
import { handleUnknownError } from '@news-mono/common'
import { get as getCookie, set as setCookie } from 'js-cookie'

const updateUserMetadata = async (userId: string) => {
    const response = await fetch(getAuth0UpdateAppMetaActionPath, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: userId,
            newsletterSignup: true,
        }),
    })

    if (!response.ok || response.status !== 201) {
        throw new Error('Error updating app metadata')
    }

    return
}

export const TNAuthenticatedNewsletterSignup = () => {
    const {
        isLoggedIn,
        emailVerified,
        isLoading,
        hasSignedupToNewsletter,
        wanUserId,
        auth0UserId,
        userName,
        userEmail,
    } = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )

    const [isSignedUpToNews, setIsSignedUpToNews] = useState<boolean>(false)

    const dispatch = useDispatch()

    // Fallback to stop spamming when accessToken has not refreshed but a request has been tried to subscribe
    // After 1 day will check the AccessToken for updated userInfo or on token refresh
    useEffect(() => {
        if (getCookie('signedUpToNewsletter')) {
            setIsSignedUpToNews(true)
        } else {
            setIsSignedUpToNews(false)
        }
    }, [])

    const { executeRecaptcha } = useRecaptcha()

    const optInLists = [
        {
            listId: 'thenightly_newsletter-news',
            listName: 'thenightly_newsletter',
        },
    ]

    const signupTheNightlyNews = async () => {
        const token = await executeRecaptcha({ action: 'subscribe' })
        try {
            const response = await fetch('/newsletter-subscribe', {
                method: 'POST',
                body: JSON.stringify({
                    token,
                    email: userEmail,
                    firstName: userName ?? '',
                    lastName: '',
                    optOutLists: optInLists.map((item) => item.listId),
                    requestToken: true,
                    createContactOnly: true,
                    source: 'The Nightly Newsletter Registration',
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (response.ok) {
                console.log('Subscribed to the nightly news')
                updateUserMetadata(auth0UserId || wanUserId)
                dispatch(authHasSignedUpNewsletterState(true))
                setCookie('signedUpToNewsletter', 'true', {
                    expires: 1,
                })
                return
            } else {
                throw new Error(
                    `Auto NewsletterSignup submission error: ${response.status} ${response.statusText}`,
                )
            }
        } catch (error) {
            const err = handleUnknownError(error)
            console.error(err)
            // Fallback to stop spamming when failed send. Will force a retry in 24hrs
            setCookie('signedUpToNewsletter', 'true', {
                expires: 1,
            })
            return
        }
    }

    if (hasSignedupToNewsletter === true || isSignedUpToNews) {
        return <></>
    }

    if (!isLoading && isLoggedIn && emailVerified && !hasSignedupToNewsletter) {
        signupTheNightlyNews()

        return <></>
    }

    return <></>
}
