import debug from 'debug'

const theNightlyStickySiteHeaderDebug = debug('site-header')
const stateDebug = theNightlyStickySiteHeaderDebug.extend('state')

// All possibilities of the display state for TheNightly Sticky Header
export type HeaderDisplayState = 'full' | 'header' | 'none'

// All possible scroll directions to be considered when manipulating the
// header components
export type HeaderScrollType = 'scroll-up' | 'scroll-down'

// A record of the scroll thresholds needed to be met before the state can be
// updated.
export const TheNightlyStickySiteHeaderStateValues: Record<
    HeaderDisplayState,
    number
> = {
    full: 0,
    header: 150,
    none: 500,
}

interface HeaderFutureStateType {
    futureState: HeaderDisplayState
    threshold: number
}

// Get the possible next state and scroll position of the header
function getNextDisplayStateAndPosition(
    currentState: HeaderDisplayState,
    scrollDirection: HeaderScrollType,
): HeaderFutureStateType | undefined {
    const isUp = scrollDirection === 'scroll-up'
    let futureState: HeaderDisplayState | undefined = undefined

    // Determine the future state based on the current state and scroll direction
    switch (currentState) {
        case 'full':
            if (!isUp) {
                futureState = 'header'
            }
            break
        case 'header':
            if (isUp) {
                futureState = 'full'
            } else {
                futureState = 'none'
            }
            break
        case 'none':
            if (isUp) {
                futureState = 'header'
            }
            break
    }

    // uh oh, future state wasn't set
    if (futureState === undefined) {
        return undefined
    }

    return {
        futureState,
        threshold: TheNightlyStickySiteHeaderStateValues[futureState],
    }
}

function hasMetTheRequirementsForFutureState(
    futureState: HeaderDisplayState,
    scrollType: HeaderScrollType,
    scrollTarget: number,
    currentScrollPos: number,
    prevScrollPos: number,
    adHeight: number,
): boolean {
    const scrollUpStateBuffer = 150

    // if the scroll is going upwards, we want to make sure that the threshold is
    // met before we attempt to re-display something
    if (scrollType === 'scroll-up') {
        if (futureState === 'header') {
            const userScrollDistance = Math.abs(
                currentScrollPos - prevScrollPos,
            )

            if (userScrollDistance >= scrollUpStateBuffer) {
                stateDebug(
                    `conditions are met for futureState 'header' to be updated on a 'scroll-up' event.`,
                )
                return true
            }
        } else if (futureState === 'full') {
            if (currentScrollPos <= adHeight / 2) {
                stateDebug(
                    `conditions are met for futureState 'full' to be updated on a 'scroll-up' event.`,
                )
                return true
            }
        }
    } else {
        const conditionsMet = currentScrollPos >= scrollTarget

        // Left commented out for future debugging use
        conditionsMet &&
            stateDebug(
                `conditions are met for futureState '${futureState}' to be updated on a 'scroll-down' event.`,
            )
        return conditionsMet
    }

    return false
}

/**
 * Used to determine the next state, this will loop until it can determine
 * if it's got the highest/lowest possible state based on the currentScrollPos
 *
 * @returns undefined if it wasn't able to get a next state, or HeaderFutureStateType if
 * it was able to determine a future state based on the scrollType
 */
export function getEndAvailableFutureState(
    startingState: HeaderDisplayState,
    scrollType: HeaderScrollType,
    currentScrollPos: number,
    prevScrollPos: number,
    adHeight: number,
): HeaderFutureStateType | undefined {
    const assumedNextState = getNextDisplayStateAndPosition(
        startingState,
        scrollType,
    )

    // If nothing was found on the first attempt, we don't want to do anything at all
    if (assumedNextState === undefined) {
        return undefined
    }

    let { futureState, threshold } = assumedNextState
    const metConditionsForFutureState = hasMetTheRequirementsForFutureState(
        futureState,
        scrollType,
        threshold,
        currentScrollPos,
        prevScrollPos,
        adHeight,
    )

    // If the conditions aren't met, we want to break out here and return undefined
    // as the scroll didn't complete the full threshold
    if (!metConditionsForFutureState) {
        return undefined
    }

    // we want to confirm that it's the highest that it can go, so we want to loop until it can't
    // loop anymore
    let atMaxValue = false
    let loopTimes = 0

    while (!atMaxValue) {
        loopTimes += 1

        // As a fail safe, stop it from looping more than 3 times if it finds a way to keep going!
        if (loopTimes > 3) {
            atMaxValue = true
            break
        }

        // Possible next state
        const tempFutureState = getNextDisplayStateAndPosition(
            futureState,
            scrollType,
        )

        // We've reached the max value, so stop here!
        if (tempFutureState === undefined) {
            atMaxValue = true
            break
        }

        const doesMeetConditionsForNextState =
            hasMetTheRequirementsForFutureState(
                tempFutureState.futureState,
                scrollType,
                tempFutureState.threshold,
                currentScrollPos,
                prevScrollPos,
                adHeight,
            )

        if (doesMeetConditionsForNextState) {
            // The conditions were met for the next state too, so we want to update the values and try
            // to get the next state after this one
            futureState = tempFutureState.futureState
            threshold = tempFutureState.threshold
        } else {
            // Else, the conditions weren't met, so we were truly at the last future state possible on
            // this scroll action, so break here.
            atMaxValue = true
            break
        }
    }

    return {
        futureState,
        threshold,
    }
}
