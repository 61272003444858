import React from 'react'
import { ErrorPage } from '../ErrorPage'
import { StyledH1, StyledH2 } from '../ErrorPage.styled'

export const TheNightlySomethingWentWrong = () => <SomethingWentWrong />

const getContent = (): JSX.Element => {
    return (
        <>
            <StyledH1>What. A. Nightmare.</StyledH1>
            <StyledH2>500 Error</StyledH2>
            <p>
                Breaking news: Like a rogue reporter, this page has gone AWOL.
            </p>
            <p>
                We’re onto it, but you might need to refresh your browser or
                come back later.
            </p>
        </>
    )
}

const SomethingWentWrong = () => <ErrorPage content={getContent} />
