import styled from '@emotion/styled'
import { colors } from '../../__styling/settings/colors'
import { zIndex } from '../../__styling/settings/z-index'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'
import {
    HeaderDisplayState,
    HeaderScrollType,
} from './helper/site-header-state-helper'
import { keyframes } from '@emotion/css'

interface StickyProps {
    height: number
    isChanging: boolean
    scrollType: HeaderScrollType
    displayState: HeaderDisplayState
}

//TODO: After test has run merge with current StyledAd
// These are currently different due to slightly different style requirements
export const StyledAd = styled('div')<StickyProps>(
    {
        display: 'none',

        [breakpoint('sm')]: {
            background: colors.thewest.greyErmine,
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
            height: 116, // stops jank
            /** leaderboardBillboard ad unit on The West has paddingBottom applied when
             *  ad unit is displaying leaderboard size
             */
            paddingBottom: calcRem(13),

            '&::after': {
                content: `''`,
                boxShadow: `0 ${calcRem(1)} ${calcRem(16)} 0 ${colors.black35}`,
                height: calcRem(10),
                position: 'absolute',
                bottom: -10,
                opacity: 1,
                width: '100%',
            },
        },

        /** remove padding when we're displaying the billboard ad */
        '@media screen and (min-width: 970px )': {
            paddingBottom: 0,
        },
    },
    ({ displayState, height, isChanging }) => ({
        '&': [
            {
                position: 'sticky',
                zIndex: zIndex.thenightly.stickySiteHeader,
                top: 0,

                animationTimingFunction: 'linear',
                animationDuration: '200ms',
                animationFillMode: 'forwards',

                // We want to disable any animations when there is no changing state going on
                // animation: isChanging ? undefined : 'none',
            },

            // STAGE 1 - SCROLLING DOWN
            // If the ad is hidden, the banner isn't and the user is scrolling down; we need to move
            // the header upwards
            displayState !== 'full' && {
                transform: `translateY(-${height}px)`,
                animationName: keyframes({
                    '0%': { transform: 'translateY(0)' },
                    '100%': {
                        transform: `translateY(-${height}px)`,
                    },
                }),
            },
            // STAGE 2 - SCROLLING UP
            // If the page is in full view again, then we want to scroll the ad slot back into view.
            isChanging &&
                displayState === 'full' && {
                    transform: `translateY(0)`,
                    animationName: keyframes({
                        '0%': { transform: `translateY(-${height}px)` },
                        '100%': {
                            transform: 'translateY(0)',
                        },
                    }),
                },
        ],
    }),
)

export const StyledHeaderContainer = styled('div')({
    background: colors.white,
    borderBottom: `1px solid ${tokens.thenightly.colors.palette.neutral[40]}`,
})
