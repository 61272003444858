import React, { FC } from 'react'
import { StyledDivider, StyledMenuItem } from '../ContentNavMenuItem.styled'
import { HomeIcon } from '../assets/HomeIcon'
import { DataLayerEventName, NavEvent } from '@news-mono/web-common'
import { getContentNavName } from '../util'

interface HomeMenuItemProps {
    onEvent: (event: NavEvent) => void
}

export const HomeMenuItem: FC<HomeMenuItemProps> = ({ onEvent }) => {
    const handleNavigate = () => {
        window.scrollTo({
            top: 0,
            behavior: 'instant' as ScrollBehavior,
        })
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: 'ContentNavMenuItem',
            payload: {
                navName: getContentNavName(true),
                navLocation: 'Header.Sticky',
                navText: 'Home',
                linkType: 'scroll',
                navPos: 0,
            },
        })
    }

    return (
        <>
            <StyledMenuItem isSelected={false} onClick={() => handleNavigate()}>
                <HomeIcon />
            </StyledMenuItem>
            <StyledDivider />
        </>
    )
}
