import React, { useContext } from 'react'
import {
    StyledActionCard,
    StyledAppDownloadImg,
    StyledButtonGroup,
    StyledDescription,
    StyledHeading,
    StyledLinkButton,
    StyledRow,
    StyledTNAppDownloadCard,
    StyledTNLogoWrapper,
} from './TNAppDownloadCard.styled'
import { Arrow } from './assets/Arrow'
import { AppIcon } from './assets/AppIcon'
import { AppStoreIcon } from './assets/AppStoreIcon'
import { GooglePlayIcon } from './assets/GooglePlayIcon'
import { ConfigurationContext } from '@news-mono/web-common'

const ActionCard: React.FC = () => {
    const config = useContext(ConfigurationContext)

    return (
        <StyledActionCard>
            <StyledRow>
                <Arrow logoWidth={72} logoHeight={36} />
                <StyledTNLogoWrapper>
                    <AppIcon />
                </StyledTNLogoWrapper>
            </StyledRow>
            <StyledHeading>The Nightly App</StyledHeading>
            <StyledDescription>
                Quality journalism in your pocket for FREE. Available for iOS
                and Android.
            </StyledDescription>
            <StyledButtonGroup>
                <StyledLinkButton to={config.appDownloadUrlApple ?? '#'}>
                    <AppStoreIcon />
                </StyledLinkButton>
                <StyledLinkButton to={config.appDownloadUrlGoogle ?? '#'}>
                    <GooglePlayIcon />
                </StyledLinkButton>
            </StyledButtonGroup>
        </StyledActionCard>
    )
}

export const TNAppDownloadCard: React.FC = () => {
    const mobileImage = require<string>('./assets/the-nightly-app-download.png')

    return (
        <StyledTNAppDownloadCard>
            <ActionCard />
            <StyledAppDownloadImg
                src={mobileImage}
                alt="Download The Nightly Mobile App"
            />
        </StyledTNAppDownloadCard>
    )
}
