import styled from '@emotion/styled'
import {
    breakpointBetween,
    breakpointMax,
    calcRem,
    fonts,
    metrics,
} from '../../__styling'
import { tokens } from '@news-mono/design-tokens'

export const StyledErrorContainer = styled.div({
    paddingLeft: calcRem(150),
    boxSizing: 'border-box',

    h1: {
        marginTop: 0,
        marginBottom: calcRem(metrics.thenightly.margins.lg),
    },
    h2: {
        marginTop: 0,
        marginBottom: calcRem(metrics.thenightly.margins.md),
    },
    p: {
        marginTop: 0,
        marginBottom: calcRem(metrics.thenightly.margins.md),
    },

    // Tablet & below
    [breakpointMax('mlg')]: {
        paddingLeft: 0,

        h1: {
            marginBottom: calcRem(metrics.thenightly.margins.md),
        },
    },
})

export const StyledH1 = styled.h1({
    fontFamily: fonts.thenightly.heading,
    fontSize: calcRem(40),
    fontWeight: 600,
    lineHeight: calcRem(48),
    letterSpacing: calcRem(-1.6),
    color: tokens.thenightly.colors.text.heading,

    // Tablet
    [breakpointBetween('xxs', 'mlg')]: {
        fontSize: calcRem(36),
        lineHeight: calcRem(40),
        letterSpacing: calcRem(-1.44),
    },

    // Mobile
    [breakpointMax('xxs')]: {
        fontSize: calcRem(32),
        lineHeight: calcRem(38),
        letterSpacing: calcRem(-1.28),
    },
})

export const StyledH2 = styled.h2({
    fontFamily: fonts.thenightly.subheading,
    fontSize: calcRem(25),
    fontWeight: 700,
    lineHeight: calcRem(32),
    letterSpacing: calcRem(-0.55),
    color: tokens.thenightly.colors.text.heading,

    // Tablet
    [breakpointBetween('xxs', 'mlg')]: {
        fontSize: calcRem(22),
        lineHeight: calcRem(32),
        letterSpacing: calcRem(-0.48),
    },

    // Mobile
    [breakpointMax('xxs')]: {
        fontSize: calcRem(20),
        lineHeight: calcRem(24),
        letterSpacing: calcRem(-0.44),
    },
})

export const StyledButtonContainer = styled.div({
    display: 'grid',
    justifyContent: 'center',
    boxSizing: 'border-box',
    paddingTop: calcRem(30),
    paddingBottom: calcRem(30),

    // Tablet & below
    [breakpointMax('mlg')]: {
        paddingTop: calcRem(10),
        paddingBottom: calcRem(10),
    },
})
