import React, { FC, useEffect } from 'react'
import { decodeId, getMenuItemsFromNodes, getContentNavName } from './util'
import {
    StyledNavigationMenuContainer,
    StyledNavigationMenuItemContainer,
} from './ContentNav.styled'
import { ContentNavMenuItem } from './ContentNavMenuItem'
import { HomeMenuItem } from './components/HomeMenuItem'
import { HamburgerMenuItem } from './components/HamburgerMenuItem'
import { ProviderChildProps } from '../FlyoutNav/FlyoutNav'
import { DataLayerEventName, NavEvent } from '@news-mono/web-common'

export interface ContentNavProps {
    isMobile: boolean
    flyOutProps?: ProviderChildProps
    handleScrollNavigation: () => void
    offset: number
    nodes: Element[]
    activeSection?: string
    onEvent: (event: NavEvent) => void
}

export const ContentNav: FC<ContentNavProps> = ({
    isMobile,
    flyOutProps,
    handleScrollNavigation,
    offset,
    activeSection,
    nodes,
    onEvent,
}) => {
    const menuItems = getMenuItemsFromNodes(nodes)

    const showMenu = menuItems.length > 0 && !!activeSection

    useEffect(() => {
        if (showMenu) {
            onEvent({
                type: DataLayerEventName.navAvailable,
                originator: 'ContentNav',
                payload: {
                    navName: getContentNavName(isMobile),
                },
            })
        } else {
            onEvent({
                type: DataLayerEventName.navHidden,
                originator: 'ContentNav',
                payload: {
                    navName: getContentNavName(isMobile),
                },
            })
        }
    }, [showMenu, isMobile, onEvent])

    return showMenu ? (
        <StyledNavigationMenuContainer className="contentNavMenu">
            {!isMobile && (
                <HamburgerMenuItem
                    flyOutProps={flyOutProps}
                    onEvent={onEvent}
                />
            )}
            <StyledNavigationMenuItemContainer>
                {isMobile && <HomeMenuItem onEvent={onEvent} />}
                {menuItems.map((menuItem, index) => (
                    <ContentNavMenuItem
                        section={menuItem.section}
                        heading={menuItem.heading}
                        offset={offset}
                        isMobile={isMobile}
                        index={index + 1}
                        isSelected={
                            activeSection === decodeId(menuItem.heading)
                        }
                        handleScrollNavigation={handleScrollNavigation}
                        key={menuItem.heading}
                        onEvent={onEvent}
                    />
                ))}
            </StyledNavigationMenuItemContainer>
        </StyledNavigationMenuContainer>
    ) : null
}
