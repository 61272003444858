import {
    StyledDivider,
    StyledFixedMenuItem,
    StyledHamburgerIcon,
    StyledMenuItem,
} from '../ContentNavMenuItem.styled'
import React, { FC } from 'react'
import { ProviderChildProps } from '@news-mono/component-library'
import { DataLayerEventName, NavEvent } from '@news-mono/web-common'
import { getContentNavName } from '../util'

interface HamburgerMenuItemProps {
    flyOutProps?: ProviderChildProps
    onEvent: (event: NavEvent) => void
}

export const HamburgerMenuItem: FC<HamburgerMenuItemProps> = ({
    flyOutProps,
    onEvent,
}) => {
    const handleOpenFlyout = () => {
        if (!flyOutProps) {
            return undefined
        }

        if (flyOutProps.mainNavOpen) {
            flyOutProps.closeNavigation()
        } else {
            flyOutProps.openNavigation()
            onEvent({
                type: DataLayerEventName.navClicked,
                originator: 'ContentNavMenuItem',
                payload: {
                    navName: getContentNavName(false),
                    navLocation: 'Header.Sticky',
                    navText: 'Hamburger',
                    linkType: 'menu',
                    navPos: 0,
                },
            })
        }
    }

    return (
        <StyledFixedMenuItem>
            <StyledMenuItem
                isSelected={false}
                onClick={() => handleOpenFlyout()}
            >
                <StyledHamburgerIcon />
            </StyledMenuItem>
            <StyledDivider />
        </StyledFixedMenuItem>
    )
}
