import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'
import { breakpoint, getComponentTheme } from '@news-mono/component-library'

export const StyledNavigationMenuContainer = styled.nav(({ theme }) => {
    const sectionContentTheme = getComponentTheme(theme, 'sectionContent')

    // 195 is a magic number margin
    const largeScreenMargin = calcRem(195).replace('rem', '')
    const maxWidth = sectionContentTheme.maxWidth.replace('rem', '')
    const largeWidth = `${+maxWidth - +largeScreenMargin}rem`

    return {
        display: 'flex',
        flexDirection: 'row',
        padding: calcRem(8),
        backgroundColor: tokens.thenightly.colors.palette.white,
        overflow: 'hidden',
        position: 'sticky',
        top: '0',
        width: '100%',
        height: calcRem(44),
        zIndex: 100,
        borderBottom: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
        borderTop: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,

        [breakpoint('sm')]: {
            maxWidth: largeWidth,
            top: calcRem(32),
            margin: 'auto',
            boxShadow: '0px 8px 20px 8px #0000000D',
            borderRadius: calcRem(22),
        },
    }
})

export const StyledNavigationMenuItemContainer = styled.div({
    listStyleType: 'none',
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'hidden',
    width: '100%',
    scrollbarWidth: 'none',
    overscrollBehavior: 'none',

    '::-webkit-scrollbar': {
        width: 0,
        height: 0,
        background: 'transparent' /* make scrollbar transparent */,
    },
})
