import React, { FC, useEffect, useRef } from 'react'
import { sectionThemeOverrider } from '@news-mono/component-library'
import { decodeId, encodeId, getContentNavName } from './util'
import { TheNightlySection } from '../../__styling/settings/sections/thenightly'
import { StyledMenuItem } from './ContentNavMenuItem.styled'
import { NavEvent } from 'libs/web-common/src/events'
import { DataLayerEventName } from '@news-mono/web-common'

interface ContentNavMenuItemProps {
    section: string
    heading: string
    offset: number
    isSelected: boolean
    isMobile: boolean
    index: number
    handleScrollNavigation: () => void
    onEvent: (event: NavEvent) => void
}

interface SectionValue {
    backgroundColor?: string
    primaryColor?: string
}

export const ContentNavMenuItem: FC<ContentNavMenuItemProps> = ({
    section,
    heading,
    offset,
    isSelected,
    handleScrollNavigation,
    isMobile,
    index,
    onEvent,
}) => {
    const ref = useRef<HTMLDivElement>(null)

    const override = sectionThemeOverrider(
        'thenightly',
        section as TheNightlySection,
    )

    const sectionValues: SectionValue | undefined = override.sectionValues

    const getOffset = () => {
        // We recalculate the offset, as this can change with new items being added to the DOM
        const offsetTop =
            document.getElementById(encodeId(heading))?.offsetTop || 0

        return offsetTop - offset
    }

    const handleNavigate = () => {
        handleScrollNavigation()
        window.scrollTo({
            top: getOffset(),
            behavior: 'instant' as ScrollBehavior,
        })
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: 'ContentNavMenuItem',
            payload: {
                navName: getContentNavName(isMobile),
                navLocation: 'Header.Sticky',
                navText: heading,
                navPos: index,
                linkType: 'scroll',
            },
        })
    }

    useEffect(() => {
        if (isSelected) {
            //I'm not sure why the setTimeout is needed, but it doesn't work without it
            setTimeout(function () {
                ref.current?.scrollIntoView({
                    behavior: 'instant' as ScrollBehavior,
                    block: 'nearest',
                    inline: 'nearest',
                })
            }, 100)
        }
    }, [isSelected])

    return (
        <StyledMenuItem
            backgroundOverride={sectionValues?.backgroundColor}
            textOverride={sectionValues?.primaryColor}
            isSelected={isSelected}
            onClick={() => handleNavigate()}
            ref={ref}
        >
            {decodeId(heading)}
        </StyledMenuItem>
    )
}
