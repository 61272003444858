import { useEffect, useState } from 'react'
import {
    containerClassNameIdentifier,
    decodeId,
    getContentNavName,
    pageContainerId,
} from './util'
import { DataLayerEventName, NavEvent } from '@news-mono/web-common'

interface UseContentNavProps {
    onEvent: (event: NavEvent) => void
    isMobile: boolean
}

export const useContentNav = ({ onEvent, isMobile }: UseContentNavProps) => {
    const [topOfPageNode, setTopOfPageNode] = useState<Element | undefined>(
        undefined,
    )
    const [nodes, setNodes] = useState<Element[]>([])
    const [activeSection, setActiveSection] = useState<string | undefined>(
        undefined,
    )
    const [retry, setRetry] = useState(true)

    const resetActiveSection = (): void => {
        setActiveSection(undefined)
    }

    useEffect(() => {
        const navPos = nodes.findIndex((node) => node.id === activeSection)

        if (navPos === -1) return

        onEvent({
            type: DataLayerEventName.navFocused,
            originator: 'UseContentNav',
            payload: {
                navName: getContentNavName(isMobile),
                navText: activeSection,
                navPos: navPos + 1,
                linkType: 'scroll',
            },
        })
    }, [activeSection, isMobile, nodes, onEvent])

    useEffect(() => {
        let topOfPageObserver: IntersectionObserver | undefined = undefined
        let contentObserver: IntersectionObserver | undefined = undefined

        if (typeof window !== 'undefined') {
            // We want to reset the active section when we get to the top of the page.
            topOfPageObserver = new IntersectionObserver(
                (entries, _) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setActiveSection(undefined)
                        }
                    })
                },
                {
                    threshold: [0.25],
                },
            )

            contentObserver = new IntersectionObserver(
                (entries, _) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setActiveSection(decodeId(entry.target.id))
                        }
                    })
                },
                {
                    // The intervals (in percentages) where the callback be fired.
                    threshold: [0, 0.4],
                },
            )

            const contentNodes = document.querySelectorAll(
                `.${containerClassNameIdentifier}`,
            )

            // When navigating from another page, this useEffect will often run before the nodes
            // have been added to the array. Adding listeners to the dom is expensive, so we rerun the
            // useEffect to look for the nodes.
            if (contentNodes.length < 1 && retry) {
                setRetry(false)
            }

            const nodesToObserve = filterObservedNodes(contentNodes)

            if (contentObserver && nodesToObserve.length > 0) {
                // Add observer to the top of the page to hide the menu when we scroll up
                if (!topOfPageNode) {
                    // Get the first child of the page container. We assume we do not want to show the menu for this
                    const pageContainerChild =
                        document.getElementById(pageContainerId)?.children[0] ??
                        undefined

                    // Make sure the first child of the parent container is not an element we want to observe in the menu
                    if (
                        pageContainerChild &&
                        !nodesToObserve.includes(pageContainerChild)
                    ) {
                        topOfPageObserver?.observe(pageContainerChild)
                    }

                    setTopOfPageNode(pageContainerChild)
                }

                // Add menu observers
                nodesToObserve.map((node) => contentObserver?.observe(node))
                nodesToObserve.map((node) => setNodeToObserved(node))
                setNodes(nodesToObserve)
                setRetry(false)
            }
        }

        return () => {
            nodes.map((node) => contentObserver?.unobserve(node))
            nodes.map((node) => setNodeToUnobserved(node))
        }
    }, [nodes, topOfPageNode, retry])

    return {
        activeSection,
        nodes,
        resetActiveSection,
    }
}

const OBSERVED_NODE_ATTRIBUTE = 'isObserved'
const OBSERVED_NODE_VALUE = 'true'

const setNodeToObserved = (node: Element) => {
    node.setAttribute(OBSERVED_NODE_ATTRIBUTE, OBSERVED_NODE_VALUE)
}

const setNodeToUnobserved = (node: Element) => {
    node.removeAttribute(OBSERVED_NODE_ATTRIBUTE)
}

const filterObservedNodes = (nodeList: NodeListOf<Element>) => {
    const nodes: Element[] = []

    for (const node of nodeList) {
        if (
            node.getAttribute(OBSERVED_NODE_ATTRIBUTE) !== OBSERVED_NODE_VALUE
        ) {
            nodes.push(node)
        }
    }

    return nodes
}
