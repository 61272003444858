import React, { useCallback, useState } from 'react'
import {
    StyledWardenTitle,
    StyledWardenParagraph,
    StyledStaticPageContainer,
    StyledLinkButton,
    StyledWebLinkButton,
    emailSentWithErrorNotification,
    emailSentWithSuccessNotification,
} from '../../warden'
import { useLocation } from 'react-router'
import {
    AppState,
    AuthenticationState,
    StateTokenCookie,
    urlSafeBase64ToObj,
} from '@news-mono/web-common'
import { useSelector } from 'react-redux'
import { Container, StyledSubTitle } from './TheNightlySignupSuccess.styled'
import { TNToastComponent } from '../../../content/TNToastNotification/TNToastNotification'
import { AlertType } from '../../../content'
import { useSendVerifyEmail } from '../../../user-setting/hooks/useSendVerifyEmail'

export const SignupSuccess: React.FC = () => {
    const [notifText, setNotifText] = useState('')
    const [notifType, setNotifType] = useState<AlertType>('success')

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const stateFromQueryParams = queryParams.get('state')
    const { isLoggedIn, wanUserId, auth0UserId, emailVerified, isLoading } =
        useSelector<AppState, AuthenticationState>(
            ({ authentication }) => authentication,
        )

    const setNotification = (text: string, type: AlertType) => {
        setNotifText(text)
        setNotifType(type)
    }

    const { mutateAsync: sendEmail } = useSendVerifyEmail(
        auth0UserId || wanUserId,
    )

    const handleSendVerificationEmail = useCallback(async () => {
        const response = await sendEmail()
        if (response) {
            setNotification(emailSentWithSuccessNotification, 'success')
        } else {
            setNotification(emailSentWithErrorNotification, 'error')
        }
    }, [sendEmail])

    const decodedState = stateFromQueryParams
        ? urlSafeBase64ToObj<StateTokenCookie>(stateFromQueryParams)
        : undefined

    const continueReadingNavigationLink =
        decodedState && decodedState.originUrl ? decodedState.originUrl : '/'

    return (
        <StyledStaticPageContainer>
            {notifText && (
                <TNToastComponent
                    text={notifText}
                    type={notifType}
                    useMaxWidth={false}
                ></TNToastComponent>
            )}
            <StyledWardenTitle>
                Thanks for being a reader of The&nbsp;Nightly.
            </StyledWardenTitle>
            {!isLoading && (
                <>
                    <StyledSubTitle>
                        {emailVerified ? 'K' : 'Verify now or k'}eep reading.
                    </StyledSubTitle>
                    {emailVerified ? (
                        <StyledWardenParagraph>
                            Continue reading and enjoy full access.
                        </StyledWardenParagraph>
                    ) : (
                        <StyledWardenParagraph>
                            Check your inbox to verify your account for full
                            access like leaving comments or continue reading and
                            verify later.
                        </StyledWardenParagraph>
                    )}
                    {isLoggedIn && !emailVerified && (
                        <StyledLinkButton onClick={handleSendVerificationEmail}>
                            Resend verification email.
                        </StyledLinkButton>
                    )}
                    <br />
                </>
            )}
            <Container>
                <StyledWebLinkButton
                    to={continueReadingNavigationLink}
                    forceInternalSSR
                >
                    Continue Reading
                </StyledWebLinkButton>
            </Container>
        </StyledStaticPageContainer>
    )
}
