import { AllEvents, AppState, useFeature } from '@news-mono/web-common'
import React, { useEffect, useState } from 'react'

import {
    StyledPageTitle,
    StyledPageSubTitle,
    StyledSavedArticlesContainer,
    StyledTitleContainer,
    StyledSavedArticleList,
} from './SavedArticles.styled'
import { Pagination } from '../../data-controllers/Pagination/Pagination'
import { Paging } from '../../data-controllers/Pagination/usePagination'
import { useQueryParams } from 'web-common/src/hooks/useQueryParams'
import { Lima, LimaLayouts } from '../../collections/Lima/Lima'
import { PublicationListEmptyResult } from '../PublicationListEmptyResult/PublicationListEmptyResult'
import { useSavedArticles } from './useSavedArticles'
import { PublicationListSkeleton } from '../PublicationListSkeleton'
import { SavedArticlesFiltersAccordion } from '../TNSavedArticlesFilters/SavedArticlesFiltersAccordion/SavedArticlesFiltersAccordion'
import { Box } from '../../compositions/Box/Box'
import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'

export interface SavedArticlesProps {
    paging: Paging
    sortBy?: string
    topics?: string
    onEvent: (event: AllEvents) => void
}

export const SavedArticles = (props: SavedArticlesProps) => {
    const { queryParams } = useQueryParams()
    const { results, isLoading } = useSavedArticles(props)
    const { isLoggedIn, isLoading: isAuthLoading } = useSelector(
        (state: AppState) => state.authentication,
    )
    const [loggedIn, setLoggedIn] = useState<boolean>(true)

    useEffect(() => {
        if (!isLoggedIn && !isAuthLoading) {
            setLoggedIn(false)
        }
    }, [isLoggedIn, isAuthLoading])

    const setPage = (page: number) => {
        if (props.paging) {
            props.paging.page = page
        }
    }

    const hasSearchResults =
        results.publications.length >= 1 && results.kind === 'listing'
    const cardLayout: LimaLayouts | undefined = undefined
    const usePagination = useFeature('search-pagination') && props.paging

    // Removing the page param as it affects navigation when beyond the first page of pagination
    const pageParam = queryParams.get('page')
    if (pageParam) {
        queryParams.delete('page')
    }

    if (!loggedIn) {
        return <Redirect to={'/'} />
    }

    return (
        <StyledSavedArticlesContainer>
            <StyledTitleContainer>
                <StyledPageTitle>Saved articles</StyledPageTitle>
                <StyledPageSubTitle>
                    Manage your saved articles and videos here.
                </StyledPageSubTitle>
            </StyledTitleContainer>
            <Box verticalSpacing="md">
                <SavedArticlesFiltersAccordion onEvent={props.onEvent} />
                {isLoading ? (
                    <PublicationListSkeleton count={3} dark={true} />
                ) : hasSearchResults ? (
                    <StyledSavedArticleList>
                        <Lima
                            displayPublicationDate={true}
                            verticalSpacing="md"
                            fixedRatios={['16:9']}
                            expectedCards={results.publications.length}
                            items={{
                                loaded: true,
                                result: results.publications,
                            }}
                            intermediateColumns={1}
                            initialColumns={1}
                            finalColumns={1}
                            fontScale={0.88}
                            cardLayout={cardLayout}
                            remainingCardLayout={{
                                type: 'landscape',
                                format: 'landscape-full-timestamp',
                            }}
                            containerWidthRatios={{
                                mobile: 1,
                                tablet: 1,
                                desktop: 1,
                            }}
                            onEvent={props.onEvent}
                            hasContextMenu
                        />
                    </StyledSavedArticleList>
                ) : (
                    <PublicationListEmptyResult />
                )}

                {hasSearchResults && usePagination && (
                    <Pagination
                        setPage={setPage}
                        itemCount={results.total ?? 0}
                        maxPages={
                            results.total
                                ? Math.ceil(
                                      results.total / props.paging.pageSize,
                                  )
                                : 0
                        }
                        paging={props.paging}
                        params={queryParams.toString()}
                        onEvent={props.onEvent}
                    />
                )}
            </Box>
        </StyledSavedArticlesContainer>
    )
}
SavedArticles.displayName = 'SavedArticles'
