import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import { colors } from '../../../__styling/settings'
import { zIndex } from '../../../__styling/settings/z-index'
import { breakpoint, themedValue } from '../../../__styling'
import { tokens } from '@news-mono/design-tokens'
import {
    HeaderDisplayState,
    HeaderScrollType,
} from '../../../content/TheNightlyStickySiteHeader/helper/site-header-state-helper'

const navHeight = 150

interface StickyProps {
    height: number
    adHeight: number
    isChanging: boolean
    scrollType: HeaderScrollType
    displayState: HeaderDisplayState
    hasMobileContentNav: boolean
}

export const StyledNavigation = styled('div')<StickyProps>(
    ({
        theme,
        height,
        adHeight,
        isChanging,
        scrollType,
        displayState,
        hasMobileContentNav,
    }) => ({
        backgroundColor: colors.white,

        borderBottom: themedValue(theme, {
            thenightly: `0.5px solid ${tokens.thenightly.colors.palette.neutral[20]}`,
            fallback: undefined,
        }),

        '&': [
            {
                position: 'sticky',
                zIndex: zIndex.thenightly.stickySiteHeader,
                top: 0,

                // Make sure that it's offset the adHeight amount
                [breakpoint('sm')]: {
                    top: adHeight,
                },

                animationTimingFunction: 'linear',
                animationDuration: '200ms',
                animationFillMode: 'forwards',
            },

            // STAGE 1 - SCROLLING DOWN
            // If the ad is hidden, the banner isn't and the user is scrolling down; we need to move
            // the header upwards
            scrollType === 'scroll-down' &&
                displayState === 'header' &&
                !hasMobileContentNav && {
                    transform: `translateY(-${adHeight}px)`,
                    animationName: keyframes({
                        '0%': { transform: 'translateY(0)' },
                        '100%': {
                            transform: `translateY(-${adHeight}px)`,
                        },
                    }),
                },

            // STAGE 2 - SCROLLING DOWN
            // We now want to display no header, so we need to move it down from there and
            // hide it entirely.
            displayState === 'none' &&
                !hasMobileContentNav && {
                    transform: `translateY(-${adHeight + height}px)`,
                    animationName: keyframes({
                        '0%': { transform: `translateY(-${adHeight}px)` },
                        '100%': {
                            transform: `translateY(-${adHeight + height}px)`,
                        },
                    }),
                },

            displayState === 'none' &&
                hasMobileContentNav && {
                    display: 'none',
                },

            // STAGE 3 - SCROLLING UP
            // Scrolling up has begun and the user has met the threshold to re-display the header
            // so we need to move back into view the appropriate amount.
            scrollType === 'scroll-up' &&
                displayState === 'header' &&
                !hasMobileContentNav && {
                    borderBottom: 'none',

                    [breakpoint('sm')]: {
                        transform: `translateY(-${adHeight}px)`,
                        animationName: keyframes({
                            '0%': {
                                transform: `translateY(-${
                                    adHeight + height
                                }px)`,
                            },
                            '100%': {
                                transform: `translateY(-${adHeight}px)`,
                            },
                        }),
                    },

                    // Else we only care about the header height, not the ad height on smaller devices.
                    transform: `translateY(0px)`,
                    animationName: keyframes({
                        '0%': {
                            transform: `translateY(-${height}px)`,
                        },
                        '100%': {
                            transform: `translateY(0px)`,
                        },
                    }),
                },

            // STAGE 4 - SCROLLING UP (TABLET / WEB)
            // If we are utilising the ad height, we need to account for that below in this section.
            isChanging &&
                displayState === 'full' && {
                    [breakpoint('sm')]: {
                        transform: `translateY(0)`,
                        animationName: keyframes({
                            '0%': { transform: `translateY(-${adHeight}px)` },
                            '100%': {
                                transform: 'translateY(0px)',
                            },
                        }),
                    },
                },

            // CONTENT NAV - MOBILE
            // Don't show transitions as they look less clean with the additional nav
            scrollType === 'scroll-up' &&
                displayState === 'header' &&
                hasMobileContentNav && {
                    borderBottom: 'none',
                },
        ],
    }),
)
