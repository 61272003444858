import React, { FC, ReactElement, useEffect } from 'react'
import {
    DimensionProps,
    withDimensions,
} from '../../../__helpers/with-dimensions'
import { StyledNavigation } from './TNNavHeaderWithStickyBanner.styled'
import { NavEvent, QuickLinks, useFeature } from '@news-mono/web-common'
import {
    ContentNav,
    desktopOffset,
    isMobileViewport,
    mobileLargeOffset,
    mobileSmallOffset,
    ProviderChildProps,
    TNQuickLinks,
    useContentNav,
} from '@news-mono/component-library'
import {
    HeaderDisplayState,
    HeaderScrollType,
} from '../../../content/TheNightlyStickySiteHeader/helper/site-header-state-helper'
import { StickyAccountBannerWrapper } from '../../warden/sticky-account-banner/StickyAccountBanner'
import { useLocation } from 'react-router'

export interface TNNavHeaderWithStickyBannerProps {
    onEvent: (event: NavEvent) => void

    adHeight: number
    isChanging: boolean
    scrollType: HeaderScrollType
    displayState: HeaderDisplayState
    navigation: JSX.Element
    quickLinks: QuickLinks
    flyOutProps?: ProviderChildProps
    handleScrollNavigation: () => void
}

const routesWithContentNav = ['/']

type InternalProps = TNNavHeaderWithStickyBannerProps & DimensionProps

export const InternalTNNavHeaderWithStickyBanner: React.FC<InternalProps> = (
    props,
) => {
    const totalHeight = props.height + props.adHeight
    const stickyBannerHeight =
        props.displayState === 'full'
            ? totalHeight
            : props.displayState === 'header'
            ? props.height
            : 0

    const { pathname } = useLocation()

    const showMobileFlag = useFeature('content-nav-mobile')
    const showDesktopFlag = useFeature('content-nav-desktop')

    const isRouteWithContentNav = routesWithContentNav.includes(pathname)
    const isMobile = isMobileViewport()

    const showMobileNav = isRouteWithContentNav && showMobileFlag && isMobile
    const showDesktopNav = isRouteWithContentNav && showDesktopFlag && !isMobile
    const showDefaultNav =
        !isRouteWithContentNav || (!showMobileNav && !showDesktopNav)

    useEffect(() => {
        document.documentElement.style.setProperty(
            '--stickyHeaderHeight',
            `${stickyBannerHeight}px`,
        )
    }, [stickyBannerHeight])

    const navProps = {
        ...props,
        isMobile: isMobile,
    }

    return (
        <>
            {showDefaultNav && <DefaultNavigation {...props} />}

            {showMobileNav && <MobileNavigationWithContentNav {...navProps} />}

            {showDesktopNav && (
                <DesktopNavigationWithContentNav {...navProps} />
            )}

            <StickyAccountBannerWrapper
                isHidden={false}
                message="Join The Nightly for full access."
            />
        </>
    )
}

interface NavigationProps extends InternalProps {
    isMobile: boolean
}

const MobileNavigationWithContentNav: FC<NavigationProps> = (props) => {
    const { nodes, activeSection } = useContentNav({
        onEvent: props.onEvent,
        isMobile: props.isMobile,
    })
    return (
        <>
            <StyledNavigation
                className="header-navigation"
                displayState={props.displayState}
                isChanging={props.isChanging}
                scrollType={props.scrollType}
                height={props.height}
                ref={props.innerRef}
                adHeight={props.adHeight}
                hasMobileContentNav={true}
            >
                {props.navigation}
                {props.displayState === 'full' ? (
                    <TNQuickLinks
                        links={props.quickLinks}
                        onEvent={props.onEvent}
                    />
                ) : (
                    <ContentNav
                        isMobile={props.isMobile}
                        flyOutProps={props.flyOutProps}
                        handleScrollNavigation={props.handleScrollNavigation}
                        offset={mobileLargeOffset}
                        nodes={nodes}
                        activeSection={activeSection}
                        onEvent={props.onEvent}
                    />
                )}
            </StyledNavigation>

            {props.displayState === 'none' && (
                <ContentNav
                    isMobile={props.isMobile}
                    flyOutProps={props.flyOutProps}
                    handleScrollNavigation={props.handleScrollNavigation}
                    offset={mobileSmallOffset}
                    nodes={nodes}
                    activeSection={activeSection}
                    onEvent={props.onEvent}
                />
            )}
        </>
    )
}

const DesktopNavigationWithContentNav: FC<NavigationProps> = (props) => {
    const { nodes, activeSection, resetActiveSection } = useContentNav({
        onEvent: props.onEvent,
        isMobile: props.isMobile,
    })

    useEffect(() => {
        if (props.displayState === 'full') {
            resetActiveSection()
        }
    }, [props.displayState, resetActiveSection])

    return (
        <>
            <StyledNavigation
                className="header-navigation"
                displayState={!activeSection ? props.displayState : 'none'}
                isChanging={props.isChanging}
                scrollType={props.scrollType}
                height={props.height}
                ref={props.innerRef}
                adHeight={props.adHeight}
                hasMobileContentNav={false}
            >
                {props.navigation}
                <TNQuickLinks
                    links={props.quickLinks}
                    onEvent={props.onEvent}
                />
            </StyledNavigation>

            {props.displayState !== 'full' && (
                <ContentNav
                    isMobile={props.isMobile}
                    flyOutProps={props.flyOutProps}
                    handleScrollNavigation={props.handleScrollNavigation}
                    offset={desktopOffset}
                    nodes={nodes}
                    activeSection={activeSection}
                    onEvent={props.onEvent}
                />
            )}
        </>
    )
}

const DefaultNavigation: FC<InternalProps> = (props) => {
    return (
        <StyledNavigation
            className="header-navigation"
            displayState={props.displayState}
            isChanging={props.isChanging}
            scrollType={props.scrollType}
            height={props.height}
            ref={props.innerRef}
            adHeight={props.adHeight}
            hasMobileContentNav={false}
        >
            {props.navigation}
            <TNQuickLinks links={props.quickLinks} onEvent={props.onEvent} />
        </StyledNavigation>
    )
}

export const TNNavHeaderWithStickyBanner =
    withDimensions<TNNavHeaderWithStickyBannerProps>(
        InternalTNNavHeaderWithStickyBanner,
    )

TNNavHeaderWithStickyBanner.displayName = 'TNNavHeaderWithStickyBanner'
