import styled from '@emotion/styled'
import { calcRem } from '../../__styling/style-functions/calc-rem'
import { tokens } from '@news-mono/design-tokens'
import { getComponentTheme } from '../../../../component-library/src/'

interface StyledMenuItemProps {
    textOverride?: string
    backgroundOverride?: string
    isSelected: boolean
}

export const StyledMenuItem = styled.div<StyledMenuItemProps>(
    ({ theme, textOverride, backgroundOverride, isSelected }) => {
        const sectionHeaderTheme = getComponentTheme(theme, 'sectionHeader')
        const textColour = textOverride ?? sectionHeaderTheme.heading?.textColor
        const backgroundColour =
            backgroundOverride ?? sectionHeaderTheme.background

        return {
            color: isSelected
                ? textColour
                : tokens.thenightly.colors.palette.neutral[50],
            background: isSelected
                ? backgroundColour
                : tokens.thenightly.colors.palette.white,
            borderRadius: calcRem(22),
            padding: `${calcRem(2)} ${calcRem(8)}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            textWrap: 'nowrap',
            whiteSpace: 'nowrap',

            '@media (hover: hover)': {
                '&:hover': {
                    color: textColour,
                    background: backgroundColour,
                    cursor: 'pointer',
                },
            },
        }
    },
)

export const StyledDivider = styled.div({
    width: calcRem(0),
    margin: calcRem(4),
    borderRight: `1px solid ${tokens.thenightly.colors.palette.neutral[30]}`,
    height: calcRem(24),
})

export const StyledFixedMenuItem = styled.div({
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    left: 0,
    backgroundColor: tokens.thenightly.colors.palette.white,
})

export const StyledHamburgerIcon = styled.div({
    display: 'flex',
    verticalAlign: 'middle',
    width: calcRem(18),
    height: calcRem(2),
    background: tokens.thenightly.colors.palette.neutral[30],
    transform: `translateY(-1px)`,
    borderRadius: calcRem(1),
    marginTop: calcRem(2),

    '&::before, &::after': {
        content: `''`,
        position: 'absolute',
        transformOrigin: 'center',
        right: 0,
        left: 0,
        height: calcRem(2),
        background: tokens.thenightly.colors.palette.neutral[30],
        borderRadius: calcRem(1),
    },

    '&::before': {
        top: calcRem(5),
    },

    '&::after': {
        bottom: calcRem(5),
    },
})
