import { useTheme } from '@emotion/react'
import { Product } from '@news-mono/web-common'
import React from 'react'
import styled from '@emotion/styled'
import { NightlyButton } from '../../buttons/NightlyButton/NightlyButton'
import { StyledButtonContainer, StyledErrorContainer } from './ErrorPage.styled'

interface ErrorPageProps {
    content?: (product: Product) => JSX.Element
}

const StyledErrorSection = styled.div<{
    attribution?: string
}>()

StyledErrorSection.displayName = 'StyledPageNotFound'

export const ErrorPage: React.FC<ErrorPageProps> = ({ content }) => {
    const theme = useTheme()

    return (
        <>
            <StyledErrorContainer>
                {content && content(theme.kind)}
                <br />
            </StyledErrorContainer>
            <StyledButtonContainer>
                <NightlyButton
                    variant={'default'}
                    text={'BACK TO HOMEPAGE'}
                    action={{
                        type: 'link',
                        to: '/',
                    }}
                    color={'primary'}
                    fill={'filled'}
                    layout={{
                        shouldGrow: true,
                        alignSelf: 'stretch',
                    }}
                />
            </StyledButtonContainer>
        </>
    )
}
