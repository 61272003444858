export const containerClassNameIdentifier = 'navigationContainer'
export const pageContainerId = 'page-container'
export const homeIdentifier = 'home'
export const mobileSmallOffset = 12
export const mobileLargeOffset = 120
export const desktopOffset = 80

export interface MenuItem {
    heading: string
    section: string
    offsetTop: number
}

export const getMenuItemsFromNodes = (nodes: Element[]): MenuItem[] => {
    const menuItems: MenuItem[] = []

    if (!nodes) {
        return menuItems
    }

    for (const node of nodes) {
        const title = node.getAttribute('title')
        const offsetTop = (node as HTMLDivElement).offsetTop

        if (title) {
            menuItems.push({
                heading: node.id,
                section: title,
                offsetTop,
            })
        }
    }

    return menuItems
}

export const encodeId = (heading: string) => {
    return heading.replace(/ /g, '-')
}

export const decodeId = (id: string) => {
    return id.replace(/-/g, ' ')
}

export const getContentNavName = (isMobile: boolean) => {
    return isMobile
        ? 'ContentNav.Mobile.TheNightly'
        : 'ContentNav.Desktop.TheNightly'
}
